<template>
  <v-menu
    offset-y
    offset-overflow
    left
    bottom
    :close-on-content-click="!!config.tableConfig.closeActionsMenuOnClick"
    content-class="actions-menu"
  >
    <template #activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
      >
        mdi-dots-vertical
      </v-icon>
    </template>

    <v-list
      style="padding: 8px;"
    >
      <template
        v-for="(action, actionIndex) in config.tableConfig.actionsConfig"
      >
        <div
          v-if="canShowAction(action)"
          :key="actionIndex"
          :style="getActionStyle(action)"
        >
          <v-list-item
            class="d-flex align-center list-item"
            :style="getActionStyle(action)"
            link
            :disabled="getActionDisabled(action)"
            @click="onActionClick(action, dataIndex)"
          >
            <v-list-item-icon
              class="align-self-center my-0 mr-1 list-icon"
            >
              <v-icon
                class="list-icon"
              >
                {{ action && action.icon ? action.icon : '' }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-title
              class="actions-list-item"
            >
              {{ action && action.label ? action.label : '' }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { isObject } from 'lodash'

export default {
  name: 'TableRowActionsMenu',

  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({})
    },
    dataIndex: {
      type: Number,
      required: true,
      default: 0
    },
    tableData: {
      type: Array,
      default: () => ([])
    },
    permissions: {
      type: Object,
      default: null
    },
    fetchTableData: {
      type: Function,
      default: () => {}
    }
  },

  methods: {
    onActionClick (action) {
      const { click } = action
      if (click && typeof click === 'function') {
        click({
          index: this.dataIndex,
          data: this.tableData[this.dataIndex],
          instance: this.$parent?.$parent?.$parent,
          fetchTableData: this.fetchTableData
        })
      }
    },

    getActionDisabled (action) {
      const { disabled } = action || {}
      let disabledValue = disabled

      if (disabled === undefined) {
        return false
      }

      if (typeof disabled === 'function') {
        disabledValue = disabled({
          permissions: this.permissions,
          data: this.tableData.length ? this.tableData[this.dataIndex] : []
        })
      }

      return !!disabledValue
    },

    canShowAction (action) {
      const { visible } = action || {}
      let visibleValue = visible

      if (visible === undefined) {
        return true
      }

      if (typeof visible === 'function') {
        visibleValue = visible({
          permissions: this.permissions,
          data: this.tableData.length ? this.tableData[this.dataIndex] : []
        })
      }

      return !!visibleValue
    },

    getActionStyle (action) {
      const { style } = action || {}
      let styleValue = {
        borderRadius: '16px'
      }

      const isActionDisabled = this.getActionDisabled(action)

      if (isActionDisabled) {
        styleValue.backgroundColor = 'rgba(35,34,34,0.12)'
        styleValue.cursor = 'not-allowed'
      }

      if (style && isObject(style)) {
        styleValue = {
          ...styleValue,
          ...style
        }
      }

      return styleValue
    }
  }
}
</script>

<style scoped>
.actions-menu {
  border-radius: 16px;
}

.actions-list-item {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 32px !important;
  color: #111827 !important;
}

.list-icon {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500 !important;
  width: 16px !important;
  height: 16px !important;
  line-height: 32px !important;
  color: #6B7280 !important;
  pointer-events: auto;
}

.list-item:hover:before {
  border-radius: 16px !important;
}
</style>
