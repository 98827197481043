<!--This type of content contains images + fields next to those images.-->
<!--If images are omitted, only fields will be shown.-->

<template>
  <div
    ref="mainDiv"
    :class="$vuetify.breakpoint.mobile ? 'tab-content-card--mobile' : 'tab-content-card'"
  >
    <!--Card title and buttons-->
    <v-card-title
      class="v-card-title"
    >
      {{ config && config.title ? config.title : '' }}

      <v-row
        v-if="config.showButtons && !$vuetify.breakpoint.mobile"
      >
        <v-col
          class="d-flex justify-end align-middle"
        >
          <universal-button
            v-if="!edit"
            type="edit"
            @click="editClicked"
          />

          <universal-button
            v-if="edit"
            class="mr-2"
            type="cancel"
            @click="cancelTab"
          />

          <universal-button
            v-if="edit"
            @click="saveTab"
          />
        </v-col>
      </v-row>
    </v-card-title>

    <!--Card content-->
    <v-card-text
      :class="getVCardTextClasses()"
      ref="cardContent"
    >
      <!--Headers part-->
      <v-row
        v-if="shouldDisplayHeaders()"
      >
        <v-col
          v-for="(header, headerIndex) in config.headers"
          :key="headerIndex"
          :cols="columnSize"
        >
          <span
            class="header-title"
          >
            {{ header }}
          </span>
        </v-col>
      </v-row>
      <v-row
        v-for="(row, rowIndex) in chunkFields()"
        :key="rowIndex"
      >
        <!--Fields part-->
        <v-col
          v-for="(field, fieldIndex) in row"
          :key="fieldIndex"
          :cols="$vuetify.breakpoint.mobile ? 9 : columnSize"
        >
            <div
              v-if="field && (!field.type || field.type === 'text')"
              @dblclick="unlockFieldsOnDblClick"
            >
              <text-field
                v-model="modifiedFormData[config.key][rowIndex][field.key]"
                :field="field"
                :validation-errors="validationErrors"
                :collection-key="config && config.key ? config.key : null"
                :collection-index="rowIndex"
                :update-data="updateData"
                :update-validation-errors="updateValidationErrors"
                dynamic
                :disabled="!field.editable || !edit"
                @clear-validation-errors="emitValidationError(config, rowIndex, field)"
              />
            </div>

            <div
              v-else-if="field && field.type === 'autocomplete'"
              @dblclick="unlockFieldsOnDblClick"
            >
              <autocomplete-field
                v-model="modifiedFormData[config.key][rowIndex][field.key]"
                :field="field"
                :items="initialAutocompletes"
                :validation-errors="validationErrors"
                :options="field.autocomplete_options"
                :collection-key="config && config.key ? config.key : null"
                :collection-index="rowIndex"
                :update-data="updateData"
                :update-validation-errors="updateValidationErrors"
                dynamic
                :disabled="!field.editable || !edit"
                @clear-validation-errors="emitValidationError(config, rowIndex, field)"
              />
            </div>

          <div
            v-else-if="field && field.type === 'combobox'"
            @dblclick="unlockFieldsOnDblClick"
          >
            <combobox-field
              v-model="modifiedFormData[config.key][rowIndex][field.key]"
              :field="field"
              :items="initialAutocompletes"
              :validation-errors="validationErrors"
              :options="field.autocomplete_options"
              :collection-key="config && config.key ? config.key : null"
              :collection-index="rowIndex"
              :disabled="!field.editable || !edit"
              @clear-validation-errors="emitValidationError(config, rowIndex, field)"
            />
          </div>
        </v-col>
        <!--Remove row button that is next to the each dynamic created row-->
        <v-col
          class="pl-0"
          style="align-content: center;"
        >
          <universal-button
            type="delete-icon"
            :ripple="false"
            text
            :disabled="!edit"
            @click="removeRow(rowIndex)"
          />
        </v-col>
      </v-row>
      <!--Add new row button-->
      <v-row
      >
        <v-col
          @dblclick="unlockFieldsOnDblClick"
        >
          <universal-button
            type="new"
            :label="$t('base.add_new_row')"
            :disabled="getNewRowButtonDisabled()"
            @click="addNewRow"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import TextField from '../fields/TextField.vue'
import AutocompleteField from '../fields/AutocompleteField.vue'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import store from '@/global/store'
import ComboboxField from '../fields/ComboboxField.vue'
import {
  cancelTabSave,
  clearValidationErrors,
  saveTab,
  setEdit
} from '../../common/content-shared-events'

const DEFAULT_COLUMNS_DIVIDER = 1
const DEFAULT_COLS_SIZE_WHEN_EDIT = 11
export default {
  name: 'DynamicColumnsContent',

  DEFAULT_COLUMNS_DIVIDER: 'DEFAULT_COLUMNS_DIVIDER',
  DEFAULT_COLS_SIZE_WHEN_EDIT: 'DEFAULT_COLS_SIZE_WHEN_EDIT',
  DEFAULT_COLS_SIZE_WHEN_NOT_EDIT: 'DEFAULT_COLS_SIZE_WHEN_NOT_EDIT',

  components: {
    ComboboxField,
    UniversalButton,
    AutocompleteField,
    TextField
  },

  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({})
    },
    formData: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: false
    },
    initialAutocompletes: {
      type: [Array, Object],
      default: () => null
    },
    validationErrors: {
      type: Object,
      default: () => {}
    },
    createMode: {
      type: Boolean,
      default: false
    },
    permissions: {
      type: Object,
      default: null
    },
    updateData: {
      type: Function,
      default: () => {}
    },
    updateValidationErrors: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {
      modifiedFormData: {},
      initialAutocompletesCopy: {}
    }
  },

  computed: {
    columnSize () {
      const headerCount = this.config.headers ? this.config.headers.length : DEFAULT_COLUMNS_DIVIDER
      return Math.floor(DEFAULT_COLS_SIZE_WHEN_EDIT / headerCount)
    }
  },

  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler (formData) {
        this.modifiedFormData = formData
      }
    },
    'config.showInitialRows': {
      immediate: true,
      handler (val) {
        if (val && this.createMode) {
          for (let i = 0; i < val; i++) {
            this.addNewRow()
          }
        }
      }
    }
  },

  mounted () {
    // Setting table wrapper height to cover full available height.
    // $nextTick() is important here because without it, not appropriate height will be set
    this.updateContainerSize()
    window.addEventListener('resize', this.updateContainerSize)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.updateContainerSize)
  },

  methods: {
    saveTab () {
      saveTab(this)
    },

    emitValidationError (config, rowIndex, field) {
      const key = config?.key
      const fieldKey = field?.key

      // Construct validation key using an array and join with '.'
      const validationKey = [key, rowIndex, fieldKey]
        .filter(key => typeof key === 'string' || !isNaN(key))
        .join('.')

      clearValidationErrors(this, validationKey)
    },

    chunkFields () {
      const chunks = []

      if (!this.config || !this.config.key) {
        store.dispatch('base/notifications/push', this.$t('base.generating_dynamic_rows_error_message'))
        return chunks
      }
      else if (!this.config.display_props || !this.config.display_props.length) {
        return chunks
      }

      const key = this.config?.key || null

      if (!key || !this.modifiedFormData[key]) {
        return chunks
      }

      // Loop through the formData[key] array
      this.modifiedFormData[key].forEach(collectionObj => {
        const row = []

        // For each display prop, push the corresponding field into the row
        this.config.display_props.forEach(propKey => {
          const field = this.config.fields.find(f => f.key === propKey)

          if (field && Object.prototype.hasOwnProperty.call(collectionObj, propKey)) {
            row.push(field)
          }
        })

        // Push the row into the chunks array
        chunks.push(row)
      })

      return chunks
    },

    updateContainerSize () {
      this.$nextTick(() => {
        if (this.$refs.cardContent && this.$refs.mainDiv && this.config && this.config.fullWidth) {
          const mainDiv = this.$refs.mainDiv.getBoundingClientRect().width - 30

          this.$refs.cardContent.style.width = `${mainDiv}px`
        }
      })
    },

    cancelTab () {
      cancelTabSave(this)
    },

    editClicked () {
      setEdit(this, true)
    },

    unlockFieldsOnDblClick () {
      if (!this.permissions || this.permissions.canEdit) {
        setEdit(this, true)
      }
    },

    addNewRow () {
      if (!this.config || !this.config.display_props) {
        store.dispatch('base/notifications/push', this.$t('base.add_new_dynamic_row_error_message'))
        return
      }

      const collectionKey = this.config?.key || null

      if (!collectionKey) {
        return
      }

      const newObject = {}

      this.config.display_props.forEach(propKey => {
        newObject[propKey] = null
      })

      if (!this.modifiedFormData[collectionKey]) {
        this.$set(this.modifiedFormData, collectionKey, [])
      }

      this.modifiedFormData[collectionKey].push(newObject)
    },

    removeRow (rowIndex) {
      if (!this.config || !this.config.key) {
        store.dispatch('base/notifications/push', this.$t('base.remove_dynamic_row_error_message'))
        return
      }

      this.modifiedFormData[this.config.key].splice(rowIndex, 1)
    },

    shouldDisplayHeaders () {
      if (!this.config || !this.config.key || !this.modifiedFormData[this.config.key] || !this.modifiedFormData[this.config.key].length) {
        return false
      }

      return this.config.headers && this.config.display_props && this.config.display_props.length && !this.$vuetify.breakpoint.mobile
    },

    getVCardTextClasses () {
      let classes = ''

      if (this.$vuetify.breakpoint.mobile) {
        classes += 'v-card-text--mobile'
      }
      else {
        classes += 'v-card-text'
      }

      if ((this.config.bordered || this.config.bordered === undefined)) {
        classes += ' v-card-text-bordered'
      }

      return classes
    },

    getNewRowButtonDisabled () {
      if (this.permissions && !this.permissions.canEdit) {
        return true
      }

      return !this.edit
    }
  }
}
</script>

<style scoped lang="scss">
.v-card-title {
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #111827;
  margin-top: 1%;
}

.v-card-text {
  width: 880px;
  margin-left: 1%;
  padding: 16px;
}

.v-card-text--mobile {
  width: 100%;
  margin-left: 1%;
  margin-right: 1%;
  border: unset !important;
}

.header-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.v-card-text-bordered {
  border: 1px solid #E5E7EB;
  border-radius: 16px;
}

.tab-content-card > *:last-child {
  border-bottom-right-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  margin-bottom: 10px;
}

.tab-content-card--mobile {
  width: 100%;
  margin: 0 !important;
}
</style>
