<template>
  <thead>
  <!-- Parent Header Row -->
  <tr>
    <template
      v-for="(header, index) in visibleHeaders"
    >
      <!-- If header has subheaders, span columns -->
      <template v-if="header.subHeaders && header.subHeaders.length">
        <th
          :key="index"
          class="group-header"
          :colspan="header.subHeaders.length"
          :style="getHeaderCustomStyles(header)"
        >
<!--          MainHeader-->
          <v-row
            style="margin: 0;"
          >
            <v-col
              class="main-header-title"
              :style="getHeaderCustomStyles(header)"
            >
              {{ header.label }}
            </v-col>
          </v-row>

<!--          Subheaders-->
          <v-row
            style="margin: 0;"
          >
            <template>
              <v-col
                v-for="(subHeader, subHeaderIndex) in header.subHeaders"
                :key="subHeaderIndex + '_subHeader'"
                class="subheader"
                :style="getHeaderCustomStyles(subHeader)"
              >
                {{ subHeader.label }}
              </v-col>
            </template>
          </v-row>
        </th>
      </template>

      <!-- Normal header without subheaders -->
      <template
        v-else-if="header.value"
      >
        <th
          :key="index"
          class="header"
          :style="getHeaderCustomStyles(header)"
        >
          {{ header.label }}
        </th>
      </template>
    </template>

    <th
      style="width: 100%;"
      class="header"
      :style="getHeaderCustomStyles()"
    />
    <!-- Actions Header (if provided) -->
    <th
      v-if="config && config.tableConfig && config.tableConfig.actionsConfig && config.tableConfig.actionsConfig.length"
      class="fixed-column"
      rowspan="2"
    >
      {{ $t('base.actions') }}
    </th>
  </tr>
  </thead>
</template>

<script>

import { isEmpty } from 'lodash'

const DEFAULT_HEADERS_HEIGHT = '76px'
export default {
  name: 'TableHeaders',

  props: {
    visibleHeaders: {
      type: Array,
      required: true,
      default: () => ([])
    },
    config: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  methods: {
    getHeaderCustomStyles (header) {
      let style = {}
      const { useFixedHeaderWidth, headersHeight } = this.config?.tableConfig?.styles ?? {}
      const { subHeaders } = header || {}

      if (!headersHeight) {
        style.height = DEFAULT_HEADERS_HEIGHT
      }

      if (useFixedHeaderWidth && headersHeight && typeof headersHeight === 'string') {
        style.height = headersHeight
      }

      if (header && !isEmpty(header.style)) {
        const headerStyle = header.style
        style = {
          ...style,
          ...headerStyle
        }
      }

      if (subHeaders && subHeaders.length) {
        style.paddingLeft = 'unset'
      }
      return style
    }
  }
}
</script>

<style scoped lang="scss">
.fixed-column {
  position: sticky;
  right: 0;
  z-index: 3;
  width: 80px;
  background-color: #F3F4F6;
  top: 0;
}

.fixed-column::before {
  content: '';
  position: absolute;
  top: 0;
  left: -15px;
  height: 100%;
  width: 15px;
  background: linear-gradient(to left, rgba(196, 193, 193, 0.3), transparent);
  z-index: -1;
}

.header {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #F3F4F6;
  position: sticky;
  top: 0;
  z-index: 3;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Satoshi', sans-serif;
  font-weight: 500;
  color: #111827;
}

.group-header {
  text-align: left;
  background-color: #F3F4F6;
  position: sticky;
  top: 0;
  z-index: 3;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Satoshi', sans-serif;
  font-weight: 500;
  color: #111827;
}

.header:nth-last-child(2), :last-child {
  border-right: unset !important;
}

.main-header-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subheader {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
</style>
