<template>
  <v-row
    class="mb-0"
  >
    <v-col
      style="display: flex; align-items: center; flex: 0 0 0; white-space: nowrap;"
    >
      <v-select
        v-model="paginationOptionsComputed.perPage"
        :items="getPerPageOptions()"
        class="per-page-select"
        hide-details="auto"
        height="20"
        style="margin-right: 15px;"
        outlined
        dense
      />

      <span
        class="per-page-text"
      >
         {{ $t('base.out_of').replace(':value', tableDataComputed.length) }}
      </span>
    </v-col>
    <v-col
      class="d-flex justify-end align-middle"
    >
<!--      Go to the first page button-->
      <button
        type="button"
        class="v-pagination__navigation mr-0"
        :style="getDoubleArrBtnStyle()"
        @click="() => { paginationOptionsComputed.currentPage = 1 }"
      >
        <v-icon
          style="color: #6B7280;"
        >
          mdi-chevron-double-left
        </v-icon>
      </button>
<!--      Standard pagination from vuetify-->
      <v-pagination
        v-model="paginationOptionsComputed.currentPage"
        :length="paginationOptionsComputed.totalPages"
        :total-visible="getTotalVisible()"
      />
<!--      Go to the last page button-->
      <button
        type="button"
        class="v-pagination__navigation ml-0"
        :style="getDoubleArrBtnStyle(true)"
        @click="() => { paginationOptionsComputed.currentPage = paginationOptionsComputed.totalPages }"
      >
        <v-icon
          style="color: #6B7280;"
        >
          mdi-chevron-double-right
        </v-icon>
      </button>
    </v-col>
  </v-row>
</template>

<script>
const DEFAULT_PAGINATION_TOTAL_VISIBLE = 7
const DEFAULT_PAGINATION_PER_PAGE_OPTIONS = [10, 20, 50, 100]
export default {
  name: 'TablePagination',

  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({})
    },
    tableData: {
      type: [Array, Object],
      required: true,
      default: () => ([])
    },
    paginationOptions: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data () {
    return {
      paginationOptionsComputed: {
        currentPage: 1,
        perPage: 10,
        count: 0,
        total: 0,
        totalPages: 0
      }
    }
  },

  computed: {
    tableDataComputed () {
      return Array.isArray(this.tableData) ? this.tableData : this.tableData?.data ?? []
    }
  },

  watch: {
    paginationOptions: {
      immediate: true,
      deep: true,
      handler (options) {
        this.paginationOptionsComputed = options
      }
    }
  },

  methods: {
    getTotalVisible () {
      return this.config &&
      this.config.tableConfig &&
      this.config.tableConfig.paginationConfig &&
      this.config.tableConfig.paginationConfig.totalVisible
        ? this.config.tableConfig.paginationConfig.totalVisible
        : DEFAULT_PAGINATION_TOTAL_VISIBLE
    },

    getPerPageOptions () {
      return this.config &&
      this.config.tableConfig &&
      this.config.tableConfig.paginationConfig &&
      this.config.tableConfig.paginationConfig.perPageOptions
        ? this.config.tableConfig.paginationConfig.perPageOptions
        : DEFAULT_PAGINATION_PER_PAGE_OPTIONS
    },

    getDoubleArrBtnStyle (last = false) {
      const style = {}

      if (!last && this.paginationOptionsComputed.currentPage === 1) {
        style.pointerEvents = 'none'
        style.opacity = '0.6'
      }
      else if (last && this.paginationOptionsComputed.currentPage === this.paginationOptionsComputed.totalPages) {
        style.pointerEvents = 'none'
        style.opacity = '0.6'
      }

      return style
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  // Setting text and background color for current active pagination item
  .v-pagination .v-pagination__item--active {
    color: #111827 !important;
    background-color: #F3F4F6 !important;
  }

  // Setting general styles for the pagination items
  .v-pagination .v-pagination__item {
    font-family: 'Satoshi', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid #E5E7EB !important;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    box-shadow: unset !important;
  }

  // Setting background color for pagination items on hover
  .v-pagination .v-pagination__item:hover {
    background-color: #F3F4F6 !important;
  }

  // Setting general styles for the pagination buttons (arrows)
  .v-pagination .v-pagination__navigation {
    width: 40px;
    height: 40px;
    box-shadow: unset !important;
    border: 1px solid #E5E7EB !important;
    border-radius: 8px;
  }

  // Styles for the double arrow pagination buttons
  .v-pagination__navigation {
    width: 40px;
    height: 40px;
    box-shadow: unset !important;
    border: 1px solid #E5E7EB !important;
    border-radius: 8px;
  }

  // Setting background color for pagination arrow buttons on hover
  .v-pagination__navigation:hover {
    background-color: #F3F4F6 !important;
  }

  ul li .v-pagination__item {
    margin: 3px !important;
  }

  // Override default navigation button's margins for the first pagination arrow button
  ul li:first-child .v-pagination__navigation {
    margin-right: 3px !important;
    margin-left: 5px !important;
  }

  // Override default navigation button's margins for the last pagination navigation button
  ul li:last-child .v-pagination__navigation {
    margin-left: 3px !important;
    margin-right: 5px !important;
  }

  // Per page select
  .per-page-select .v-input__control {
    border-radius: 8px;
  }

  // Setting font styles for selected value in per page select
  .per-page-select .v-input__control .v-input__slot .v-select__slot .v-select__selections .v-select__selection--comma {
    font-family: 'Satoshi', sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }

  // Setting border color for the per page select field (this also disable hover)
  .per-page-select .v-input__control > .v-input__slot fieldset {
    border-color: #E5E7EB !important;
  }

  .v-list-item__title {
    font-family: 'Satoshi', sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }
}

.per-page-text {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}
</style>
